import React from 'react'
import "./index.css"
import jbPortrait from "../../../Assets/jbPortrait.jpg"
import resume from "../../../Assets/CV.png"


const index = () => {
    return (
        <div className="bio">
            <div className="bioTop">
                <div className="bioTopL">
                    {/* <div className="rLine" /> */}
                    <div className="bioTopText">
                        <div className="bioTitle">Julian Biscombe</div>
                        <br />
                        <div className="bioSubTitle">Software Developer</div>
                        <br />
                        <div className="bioContent">Hi, my name is Julian and I am a web site developer.
                        I specialize mainly in Front-End developement, design
and project management</div>

                    </div>
                </div>

                <div className="bioImg" style={{ backgroundImage: `url(${jbPortrait})` }} />
            </div>
            <div className="bioBot">

            </div>
            <button onClick={() => navigator.clipboard.writeText('biscombe.j@gmail.com')} className="btnLabelMobile">Contact Me</button>
            <a href={resume} download="CVJulianBiscombe" style={{ textDecoration: "none" }}>
                <button className="btnLabelMobile btnLabelUnMarked">Download Resume</button>
            </a>
            {/* <hr className="devider" /> */}
        </div>
    )
}

export default index
