import React from 'react'
import "./index.css"
import ServicesComp from "./ServicesComp"
import { serviceContent } from "../../../Assets/Data"


const Index = () => {





    return (
        <div className="services">
            <div className="aboutMe">
                <div className="aboutMeTitle">About Me</div>
                <p className="servicesBioBody">I have alwas enjoyed developing websites and everything that comes with it, since an early age. From front-end to back-end and managing the entire process. My experience allows me to get things done in a fast and simple manner, as well as
seeing possible issues in the forefront. </p>
            </div>
            <div className="servicesContent">
                <div className="aboutMeTitle">Services</div>
                <div className="skillsComp">
                    {serviceContent.map((item, index) => (
                        <div className="skill" key={index}>
                            <div data-aos="fade" data-aos-once="true" key={index} className="servContent" >
                                <ServicesComp title={item.title} body={item.body} />
                            </div>
                        </div>

                    ))}
                </div>
            </div>
        </div>
    )
}

export default Index
