import React from 'react'
import "./PortFComp.css"

const PortFComp = ({ portFItem, even }) => {
    return (
        <div className={even % 2 ? "portFComp" : "portFComp reverse"}>
            <div className="portFCompText">
                <h2 className="portFCompTitle">{portFItem.title}</h2>
                <ul className="portFCompBody">
                    {portFItem.body.map((item, index) => (
                        <li style={{ marginBottom: "10px" }} key={index}>{item}</li>
                    ))}
                </ul>
                <a href={portFItem.link}>
                    <button className={portFItem.active ? "portFBtnActive" : "portFBtnActive portFBtnInactive"}>View Website</button>
                </a>
            </div>
            <a href={portFItem.link}>
                <div className={even % 2 ? "portFCompImg" : "portFCompImgUneven"} style={{ backgroundImage: `url(${portFItem.image})` }}></div>
            </a>
        </div>

    )
}

export default PortFComp
