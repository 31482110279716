import './App.css';
import Bio from "./Pages/WebDev/Bio/Index"
import Services from "./Pages/WebDev/Services/Index"
import Portfolio from "./Pages/WebDev/Portfolio/Index"
import React, { useEffect, useState } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import Lines from './Pages/WebDev/Lines/Lines';
import Skydive from "./Pages/Skydive/Index"


function App() {
  const [scrollPosition, setScrollPosition] = useState(0);

  const windowThreshold = window.innerHeight / 3
  var limit = Math.floor(0.92 * (Math.max(document.body.scrollHeight, document.body.offsetHeight,
    document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight) - window.innerHeight))


  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    Aos.init({ duration: 1500 })
  }, [])


  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <div className="app">
      {/* <Skydive /> */}
      <Lines scrollPosition={scrollPosition} windowThreshold={windowThreshold} windowLimit={limit} />
      <div className="components">
        <Bio />
        <Services />
        <Portfolio />
      </div>
      <div className="footerMobile">
        <div className="footerTitleMobile">Created and designed by Julian Biscombe.</div>
        <a href="" className="impressumMobile">Impressum</a>

      </div>

    </div>
  );
}

export default App;
