import React from 'react'
import "./index.css"
import PortFComp from './PortFComp'
import { portFContent } from "../../../Assets/Data"



const Index = () => {



    return (
        <div className="portfolio">
            <h1 style={{
                marginBottom: "100px", color: "rgb(41, 48, 60)", fontSize: "50px", textAlign: "center"
            }}>Current Projects</h1>
            {portFContent.map((item, index) => (
                <div key={index} data-aos="fade" data-aos-once="true">
                    <PortFComp portFItem={item} even={index} />
                </div>
            ))}
        </div>
    )
}

export default Index
