import dashboard from "./dashboard.png"

import droov from "./droov.png"
import exp1_0 from "./skydiveGallery/exp1_0.jpg"
import exp1_1 from "./skydiveGallery/exp1_1.jpg"
import exp1_2 from "./skydiveGallery/exp1_2.jpg"
import exp1_3 from "./skydiveGallery/exp1_3.jpg"
import exp1_4 from "./skydiveGallery/exp1_4.jpg"
import exp1_5 from "./skydiveGallery/exp1_5.jpg"
import exp1_6 from "./skydiveGallery/exp1_6.jpg"

import exp2_0 from "./skydiveGallery/exp2_0.jpg"
import exp2_1 from "./skydiveGallery/exp2_1.jpg"
import exp2_2 from "./skydiveGallery/exp2_2.jpg"
import exp2_3 from "./skydiveGallery/exp2_3.jpg"
import exp2_4 from "./skydiveGallery/exp2_4.jpg"
import exp2_5 from "./skydiveGallery/exp2_5.jpg"
import exp2_6 from "./skydiveGallery/exp2_6.jpg"
import exp2_7 from "./skydiveGallery/exp2_7.jpg"

import exp3_0 from "./skydiveGallery/exp3_0.jpg"
import exp3_1 from "./skydiveGallery/exp3_1.jpg"
import exp3_2 from "./skydiveGallery/exp3_2.jpg"
import exp3_3 from "./skydiveGallery/exp3_3.jpg"
import exp3_4 from "./skydiveGallery/exp3_4.jpg"
import exp3_5 from "./skydiveGallery/exp3_5.jpg"

import exp4_0 from "./skydiveGallery/exp4_0.jpg"



export const serviceContent = [{
    title: "Web Developement",
    body: ["JavaScript", "React", "Tailwind", "CSS", "Python", "NodeJS", "Typescript"]
},
{
    title: "iOS-App Developement",
    body: ["Swift", "Project management"]
},
{
    title: "Web/App Design",
    body: ["Sketch", "Figma"]
},]

export const portFContent = [{
    title: "PaarFit Progressive Web App",
    body: ["Developed front-end and responsive components for maximum scalability by using React JS, Typescript and Tailwind CSS", "MaterialUIs SDK was used for increasing the developement process and reducing future maintanace labor", "Data storage was handled by using MongoDB, and serverless architecture for push notification as well as Firestore Database for managin the user data", "Firestore Authentication for handling user authentication processes"],
    image: `${dashboard}`,
    link: "",
    active: false,
},
{
    title: "Droov Progressive Web App",
    body: ["Developed responsive and scalable front-end using React JS, Typescript and Tailwind CSS", "Increasing developement speed by utilizing MaterialUI SDK", "Back-end was handled by using Firebase and NodeJS", "Handled user data via MongoDB, Firebase"],
    image: `${droov}`,
    link: "https://www.droov.io/",
    active: true,

}
    // {
    //     title: "PaarFit",
    //     body: ["Online trainings programm for middle age couples"],
    //     image: `${profile}`,
    //     link: "",
    //     active: false,

    // }
]


export const skydiveExp = [{
    title: "Experience One",
    mainImg: exp1_0,
    gallery: [
        {
            title: "Title1",
            description: "Fun day 1",
            img: exp1_1
        },
        {
            title: "Title2",
            description: "Fun day 2",
            img: exp1_2
        },
        {
            title: "Title3",
            description: "The href attribute requires a valid value to be accessible. Provide a valid, navigable address as the href value. If you cannot provide a valid href, but still need the element to resemble a link.",
            img: exp1_3
        },
        {
            title: "Title4",
            description: "Fun day 4",
            img: exp1_4
        },
        {
            title: "Title5",
            description: "Fun day 5",
            img: exp1_5
        }, {
            title: "Title6",
            description: "Fun day 6",
            img: exp1_6
        }]
},
{
    title: "Experience Two",
    mainImg: exp2_0,
    gallery: [
        {
            title: "Title1",
            description: "Fun day 1",
            img: exp2_1
        },
        {
            title: "Title2",
            description: "Fun day 2",
            img: exp2_2
        },
        {
            title: "Title3",
            description: "The href attribute requires a valid value to be accessible. Provide a valid, navigable address as the href value. If you cannot provide a valid href, but still need the element to resemble a link.",
            img: exp2_3
        },
        {
            title: "Title3",
            description: "The href attribute requires a valid value to be accessible. Provide a valid, navigable address as the href value. If you cannot provide a valid href, but still need the element to resemble a link.",
            img: exp2_4
        },
        {
            title: "Title3",
            description: "The href attribute requires a valid value to be accessible. Provide a valid, navigable address as the href value. If you cannot provide a valid href, but still need the element to resemble a link.",
            img: exp2_5
        },
        {
            title: "Title3",
            description: "The href attribute requires a valid value to be accessible. Provide a valid, navigable address as the href value. If you cannot provide a valid href, but still need the element to resemble a link.",
            img: exp2_6
        },
        {
            title: "Title3",
            description: "The href attribute requires a valid value to be accessible. Provide a valid, navigable address as the href value. If you cannot provide a valid href, but still need the element to resemble a link.",
            img: exp2_7
        }]
},
{
    title: "Experience Three",
    mainImg: exp3_0,
    gallery: [
        {
            title: "Title1",
            description: "Fun day 1",
            img: exp3_1
        },
        {
            title: "Title2",
            description: "Fun day 2",
            img: exp3_2
        },
        {
            title: "Title3",
            description: "The href attribute requires a valid value to be accessible. Provide a valid, navigable address as the href value. If you cannot provide a valid href, but still need the element to resemble a link.",
            img: exp3_3
        },
        {
            title: "Title3",
            description: "The href attribute requires a valid value to be accessible. Provide a valid, navigable address as the href value. If you cannot provide a valid href, but still need the element to resemble a link.",
            img: exp3_4
        },
        {
            title: "Title3",
            description: "The href attribute requires a valid value to be accessible. Provide a valid, navigable address as the href value. If you cannot provide a valid href, but still need the element to resemble a link.",
            img: exp3_5
        }]
},
]