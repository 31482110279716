import React from 'react'
import "./ServicesComp.css"


const ServicesComp = ({ title, body }) => {
    return (
        <div className="servicesComp">
            <div className="servicesCompL">
                <p className="sComptitle">{title}</p>
            </div>
            <div className="ServicesCompR">
                {body.map((item, index) => (
                    <div className="serviceCompItem" key={index}>
                        <div className="servicesCompTitle">{item}</div>
                        {body.indexOf(item) === body.length - 1 ?
                            ""
                            : <div className="dot" />
                        }
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ServicesComp
