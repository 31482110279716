import React from 'react'
import resume from "../../../Assets/CV.png"
import "./Lines.css"


const Lines = ({ scrollPosition, windowThreshold, windowLimit }) => {
    return (
        <div>
            <div className="lines">
                <div className="lLine ">
                    <div className={scrollPosition > windowLimit ? "footer footerVis" : "footer"}>
                        <div className="footerTitle">Created and designed by Julian Biscombe.</div>
                        <a href="" className="impressum">Impressum</a>
                    </div>
                </div>
                <div className={scrollPosition >= windowThreshold ? "mLine" : "mLine lineVisible"}>
                    <a href={resume} download="CVJulianBiscombe">

                        {scrollPosition >= windowThreshold ?
                            <div className="btnMarker " />
                            : ""}
                        <button className={scrollPosition >= windowThreshold ? "btnLabel" : "btnLabel btnMLabelVis"}>Download Resume</button>
                    </a>
                </div>
                <div className={scrollPosition >= windowThreshold ? "rLine" : "rLine lineVisible"} onClick={() => navigator.clipboard.writeText('biscombe.j@gmail.com')}>
                    {scrollPosition >= windowThreshold ?
                        <div className="btnMarker" />
                        : ""}
                    <button className={scrollPosition >= windowThreshold ? "btnLabel" : "btnLabel btnRLabelVis"} >Contact Me</button>
                </div>
            </div>
        </div>
    )
}

export default Lines
